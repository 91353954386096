<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="auth d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="auth-aside d-flex flex-column flex-row-auto"
        v-bind:class="{
          'bg-login': $route.name == 'login',
          'bg-register': $route.name == 'register'
        }"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <a href="#" class="text-center mb-10">
            <img
              :src="$route.name == 'login' ? logoDark : logoLight"
              class="max-h-70px"
              alt=""
            />
          </a>
          <h3
            v-if="$route.name == 'login'"
            class="font-weight-bolder text-center font-size-h4 text-dark-50 line-height-x"
          >
            Η μεγαλύτερη πλατφόρμα για την <br />ταυτότητα κτιρίου στην
            επικράτεια.
          </h3>
          <h3
            v-if="$route.name == 'register'"
            class="font-weight-bolder text-center font-size-h4 font-size-h1-lg text-white"
          >
            We are <br />open
          </h3>
        </div>
        <div
          class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
          :style="{
            backgroundImage: `url(${
              $route.name == 'login' ? loginBg : registerBg
            })`
          }"
        ></div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="auth-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
        v-bind:class="{
          'bg-login-form': $route.name == 'login'
        }"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <transition name="fade-in-up">
            <router-view></router-view>
          </transition>
        </div>
        <!--begin::Content footer-->
        <div
          class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0"
        >
          <a href="#" class="text-primary font-weight-bolder font-size-h5"
            >Terms</a
          >
          <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5"
            >Plans</a
          >
          <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5"
            >Contact Us</a
          >
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/auth-custom.scss";
.bg-login {
  background: #ffffff;
}

.bg-login-form {
  background-color: #f3f5f9;
}

.bg-register {
  background: linear-gradient(147.04deg, #ca7b70 0.74%, #5a2119 99.61%);
}
</style>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "auth",
  data() {
    return {
      logoDark: "/media/building-id/logo-dark-sm.png",
      logoLight: "/media/building-id/logo-light-sm.png",
      loginBg: "/media/svg/illustrations/login-visual-5.svg",
      registerBg: "/media/svg/illustrations/login-visual-4.svg"
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
      );
    }
  }
};
</script>
