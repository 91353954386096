var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column flex-root"},[_c('div',{staticClass:"auth d-flex flex-column flex-lg-row flex-column-fluid bg-white",attrs:{"id":"kt_login"}},[_c('div',{staticClass:"auth-aside d-flex flex-column flex-row-auto",class:{
        'bg-login': _vm.$route.name == 'login',
        'bg-register': _vm.$route.name == 'register'
      }},[_c('div',{staticClass:"d-flex flex-column-auto flex-column pt-lg-40 pt-15"},[_c('a',{staticClass:"text-center mb-10",attrs:{"href":"#"}},[_c('img',{staticClass:"max-h-70px",attrs:{"src":_vm.$route.name == 'login' ? _vm.logoDark : _vm.logoLight,"alt":""}})]),(_vm.$route.name == 'login')?_c('h3',{staticClass:"font-weight-bolder text-center font-size-h4 text-dark-50 line-height-x"},[_vm._v(" Η μεγαλύτερη πλατφόρμα για την "),_c('br'),_vm._v("ταυτότητα κτιρίου στην επικράτεια. ")]):_vm._e(),(_vm.$route.name == 'register')?_c('h3',{staticClass:"font-weight-bolder text-center font-size-h4 font-size-h1-lg text-white"},[_vm._v(" We are "),_c('br'),_vm._v("open ")]):_vm._e()]),_c('div',{staticClass:"aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center",style:({
          backgroundImage: ("url(" + (_vm.$route.name == 'login' ? _vm.loginBg : _vm.registerBg) + ")")
        })})]),_c('div',{staticClass:"auth-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto",class:{
        'bg-login-form': _vm.$route.name == 'login'
      }},[_c('div',{staticClass:"d-flex flex-column-fluid flex-center"},[_c('transition',{attrs:{"name":"fade-in-up"}},[_c('router-view')],1)],1),_vm._m(0)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0"},[_c('a',{staticClass:"text-primary font-weight-bolder font-size-h5",attrs:{"href":"#"}},[_vm._v("Terms")]),_c('a',{staticClass:"text-primary ml-10 font-weight-bolder font-size-h5",attrs:{"href":"#"}},[_vm._v("Plans")]),_c('a',{staticClass:"text-primary ml-10 font-weight-bolder font-size-h5",attrs:{"href":"#"}},[_vm._v("Contact Us")])])}]

export { render, staticRenderFns }